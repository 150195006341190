.main-drop .inputdropdown {
  background: #333333 0% 0% no-repeat padding-box;
  display: flex;
  height: 40px;
}
.main-drop .inputdropdown-inputbox {
  width: 80%;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  height: 40px;
}
.main-drop .inputdropdown-inputbox input {
  height: 45px !important;
}
.main-drop .inputdropdown-ddbox {
  width: 20%;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  height: 40px;
}
.main-drop .ddbox-toggle {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  height: 40px !important;
}
.main-drop .ddbox-toggle input {
  height: 45px !important;
}
.main-drop .divider {
  height: 20px;
  width: 1px;
  background-color: #5C5C5C;
  align-self: center;
}
.mob-select .custom-dropdown .custom-dropdown-toggle {
  padding: 0px 10px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;
  border: none;
  color: #ffffff;
  font-size: 14px;
  background: transparent;
}
.mob-select .custom-dropdown .custom-dropdown-toggle .dropdown-selected input {
  height: 44px;
  cursor: pointer;
  padding: 0px !important;
  border: 1px solid #333333;
  color: #ffffff;
  background-color: #333333;
  font-size: 13px;
  font-weight: bold;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.custom-select__option {
  display: flex !important;
  justify-content: space-between;
}

.gold-token-wrapper .completed {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100%;
}
.gold-token-wrapper .top-section {
  margin-top: 30px;
  display: flex;
  justify-content: space-evenly;
  border-bottom: 1px solid #222222;
  padding-bottom: 25px;
}
.gold-token-wrapper .top-section .left-section {
  width: 20%;
  display: inline-flex;
  justify-content: flex-start;
  background: #313B4D33;
  border: 1px solid #313B4D74;
  box-shadow: 0px 4px 20px #00000099;
  text-align: center;
  padding: 25px;
  border-radius: 2px;
  flex-wrap: wrap;
  position: relative;
  height: 240px;
}
.gold-token-wrapper .top-section .left-section::before,
.gold-token-wrapper .top-section .left-section::after {
  width: 25px;
  height: 25px;
  content: '';
  position: absolute;
  left: -12px;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #0b0e11;
  border-radius: 100%;
  border-right: 1px solid #313B4D74;
}
.gold-token-wrapper .top-section .left-section::after {
  right: -12px;
  left: auto;
  border-left: 1px solid #313B4D74;
  border-right: 0;
}
.gold-token-wrapper .top-section .left-section img {
  display: block;
  margin: 0 auto;
}
.gold-token-wrapper .top-section .left-section button.download-btn {
  width: auto !important;
  display: block;
  color: #767676;
  font-size: 12px;
  border-radius: 60px;
  background: #12161D;
  border: 1px solid #333333;
  height: auto;
  padding: 10px 15px 10px 40px;
  margin: 30px auto 0;
  position: relative;
}
.gold-token-wrapper .top-section .left-section button.download-btn::before {
  content: '';
  width: 15px;
  height: 20px;
  background: url('/assets/images/document.svg') no-repeat;
  background-size: 100%;
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.gold-token-wrapper .top-section .right-section {
  width: 80%;
  display: inline-flex;
  justify-content: flex-start;
  padding: 10px 20px;
  flex-flow: wrap;
  position: relative;
}
.gold-token-wrapper .top-section .right-section span.my-purchase {
  position: absolute;
  right: 10px;
  top: 0;
  background: #1ECFAA;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 600;
  border-radius: 45px;
  padding: 2px 10px;
}
.gold-token-wrapper .top-section .right-section p {
  font-size: 13px;
  color: #757575;
}
.gold-token-wrapper .top-section .right-section strong {
  color: #9C9E9F;
  font-size: 16px;
  margin-bottom: 5px;
}
.gold-token-wrapper .top-section .right-section .inline-list {
  width: 100%;
  border-top: 1px solid #222222;
  border-bottom: 1px solid #222222;
  padding: 20px 0;
}
.gold-token-wrapper .top-section .right-section .inline-list li {
  width: 25%;
  display: inline-block;
}
.gold-token-wrapper .top-section .right-section .inline-list li span {
  font-size: 13px;
  color: #757575;
  display: block;
  margin-bottom: 5px;
}
.gold-token-wrapper .top-section .right-section .inline-list li b {
  color: #FFFFFF;
  font-size: 14px;
}
.gold-token-wrapper .top-section .right-section .token-purchased {
  width: 100%;
  position: relative;
}
.gold-token-wrapper .top-section .right-section .token-purchased b {
  color: #676767;
  font-size: 16px;
  font-weight: 400;
}
.gold-token-wrapper .top-section .right-section .token-purchased strong {
  position: absolute;
  right: 0;
  bottom: -20px;
  color: #FFFFFF;
  font-weight: 400;
  font-size: 14px;
}
.gold-token-wrapper .top-section .right-section .token-purchased em {
  position: absolute;
  right: 0;
  top: 0;
  color: #C39F42;
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
}
.gold-token-wrapper .top-section .right-section .token-purchased .progress-wrap {
  width: 100%;
  height: 16px;
  background: #222222;
  border-radius: 65px;
  display: block;
  margin: 10px 0;
  position: relative;
  overflow: hidden;
}
.gold-token-wrapper .top-section .right-section .token-purchased .progress-wrap i {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 30%;
  height: 100%;
  background: #A08337;
  border-radius: 55px;
}
.gold-token-wrapper .mid-section {
  padding: 30px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.gold-token-wrapper .mid-section .left-section {
  width: 65%;
  display: inline-flex;
  flex-flow: wrap;
  justify-content: flex-start;
  padding-right: 30px;
}
.gold-token-wrapper .mid-section .left-section h3 {
  font-size: 20px;
  color: #9C9E9F;
  width: 100%;
  position: relative;
}
.gold-token-wrapper .mid-section .left-section h3::after {
  content: '';
  position: absolute;
  width: 80px;
  height: 2px;
  background: #C39F42;
  left: 0;
  bottom: -10px;
}
.gold-token-wrapper .mid-section .left-section p {
  width: 100%;
  color: #D1D1D1;
  font-size: 12px;
  margin: 15px 0;
}
.gold-token-wrapper .mid-section .left-section .digital-everyone {
  padding-left: 0;
}
.gold-token-wrapper .mid-section .left-section .digital-everyone li {
  color: #D1D1D1;
  font-size: 12px;
  list-style-type: none;
  padding: 3px;
  position: relative;
  padding-left: 15px;
}
.gold-token-wrapper .mid-section .left-section .digital-everyone li::before {
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background: #C39F42;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
}
.gold-token-wrapper .mid-section .left-section .blockchain-list {
  padding: 0;
  width: 100%;
}
.gold-token-wrapper .mid-section .left-section .blockchain-list li {
  list-style-type: none;
  font-size: 12px;
  margin-bottom: 5px;
}
.gold-token-wrapper .mid-section .left-section .blockchain-list li span,
.gold-token-wrapper .mid-section .left-section .blockchain-list li strong {
  background: #222222;
  padding: 10px;
  width: 50%;
  display: inline-block;
  color: #7C7C7C;
}
.gold-token-wrapper .mid-section .left-section .blockchain-list li strong {
  color: #D1D1D1;
  font-weight: 400;
  width: 49%;
  margin-left: 5px;
}
.gold-token-wrapper .mid-section .right-section {
  width: 35%;
  display: inline-flex;
  background: #191919;
  border-radius: 10px;
  box-shadow: 0px 6px 30px #000000;
  border: 1px solid #313B4D99;
  flex-wrap: wrap;
  padding: 20px;
  justify-content: flex-end;
  max-height: 400px;
}
.gold-token-wrapper .mid-section .right-section b {
  width: 100%;
  color: #FFFFFF;
  font-size: 16px;
  text-align: center;
}
.gold-token-wrapper .mid-section .right-section .color-count li b {
  color: #C39F42 !important;
}
.gold-token-wrapper .mid-section .right-section .time-count {
  padding-left: 0;
  width: 100%;
}
.gold-token-wrapper .mid-section .right-section .time-count li {
  display: inline-block;
  background: #191919;
  border: 1px solid #222222;
  box-shadow: 0px 3px 6px #00000099;
  padding: 15px 25px;
  text-align: center;
  margin: 0 10px;
  width: 20%;
}
.gold-token-wrapper .mid-section .right-section .time-count li b {
  font-size: 20px;
}
.gold-token-wrapper .mid-section .right-section .time-count li span {
  color: #9C9E9F;
  font-size: 10px;
  display: block;
  text-transform: uppercase;
}
.gold-token-wrapper .mid-section .right-section .time-count.start li b {
  color: #1ECFAA;
}
.gold-token-wrapper .mid-section .right-section .time-count.end li b {
  color: #C39F42;
}
.gold-token-wrapper .mid-section .right-section strong {
  color: #7E7E7E;
  width: 100%;
  font-size: 11px;
  text-align: center;
  border-bottom: 1px solid #222222;
}
.gold-token-wrapper .mid-section .right-section .time-count-bottom {
  width: 100%;
  padding: 25px 0 0;
  display: flex;
  flex-wrap: wrap;
}
.gold-token-wrapper .mid-section .right-section .time-count-bottom label {
  color: #9C9E9F;
  font-size: 15px;
  width: 100%;
  margin-bottom: 0;
}
.gold-token-wrapper .mid-section .right-section .time-count-bottom .input-box {
  width: 65%;
  display: inline-flex;
  height: 40px;
  margin-right: 10px;
}
.gold-token-wrapper .mid-section .right-section .time-end-bottom {
  width: 100%;
  flex-wrap: wrap;
  padding: 25px 0 0;
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
}
.gold-token-wrapper .mid-section .right-section .time-end-bottom .purchase,
.gold-token-wrapper .mid-section .right-section .time-end-bottom .currency {
  display: inline-flex;
  width: 50%;
  padding: 0 20px 0 0;
  flex-wrap: wrap;
}
.gold-token-wrapper .mid-section .right-section .time-end-bottom .purchase .red-color,
.gold-token-wrapper .mid-section .right-section .time-end-bottom .currency .red-color {
  color: #BE2718;
}
.gold-token-wrapper .mid-section .right-section .time-end-bottom .purchase label,
.gold-token-wrapper .mid-section .right-section .time-end-bottom .currency label {
  color: #9C9E9F;
  font-size: 15px;
  width: 100%;
  margin-bottom: 0;
}
.gold-token-wrapper .mid-section .right-section .time-end-bottom .purchase span,
.gold-token-wrapper .mid-section .right-section .time-end-bottom .currency span {
  color: #7E7E7E;
  font-size: 11px;
  text-transform: uppercase;
}
.gold-token-wrapper .mid-section .right-section .time-end-bottom .currency {
  padding-right: 0;
}
.gold-token-wrapper .modal-body-container {
  width: 60%;
}
.gold-token-wrapper .modal-body-container .token-deposit-modal {
  padding: 20px;
}
.gold-token-wrapper .modal-body-container .token-deposit-modal .top-section {
  border-bottom: 1px solid #222222;
  margin-top: 0;
  padding-bottom: 10px;
}
.gold-token-wrapper .modal-body-container .token-deposit-modal .top-section strong {
  color: #9C9E9F;
  font-size: 18px;
  font-weight: 400;
  width: 70%;
  padding-right: 20px;
  justify-content: center;
  align-items: center;
}
.gold-token-wrapper .modal-body-container .token-deposit-modal .top-section strong em {
  color: #C39F42;
  font-weight: 500;
  font-style: normal;
}
.gold-token-wrapper .modal-body-container .token-deposit-modal .top-section span {
  background: #3D3F4333;
  border: 1px dotted #C39F424D;
  position: relative;
  color: #7E7E7E;
  font-size: 12px;
  align-items: center;
  width: 28%;
  box-shadow: 0px 4px 20px #00000099;
  border-radius: 15px;
  justify-content: center;
  display: inline-flex;
  height: 40px;
}
.gold-token-wrapper .modal-body-container .token-deposit-modal .top-section span b {
  color: #C39F42;
  font-size: 14px;
}
.gold-token-wrapper .modal-body-container .token-deposit-modal .top-section span::before,
.gold-token-wrapper .modal-body-container .token-deposit-modal .top-section span::after {
  width: 20px;
  height: 20px;
  content: '';
  position: absolute;
  left: -10px;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #171717;
  border-radius: 100%;
  border-right: 1px solid #C39F424D;
}
.gold-token-wrapper .modal-body-container .token-deposit-modal .top-section span::after {
  right: -10px;
  left: auto;
  border-left: 1px solid #C39F424D;
  border-right: 0;
}
.gold-token-wrapper .modal-body-container .token-deposit-modal .mid-section {
  padding: 10px 0;
  position: relative;
}
.gold-token-wrapper .modal-body-container .token-deposit-modal .mid-section .time-limit {
  position: absolute;
  top: 20px;
  right: 0;
}
.gold-token-wrapper .modal-body-container .token-deposit-modal .mid-section .time-limit span {
  display: inline-block;
  background: #191919;
  border-radius: 5px;
  border: 1px solid #222222;
  box-shadow: 0px 3px 6px;
  margin-left: 10px;
  padding: 10px 15px;
}
.gold-token-wrapper .modal-body-container .token-deposit-modal .mid-section .time-limit span b {
  color: #C39F42;
  font-size: 20px;
}
.gold-token-wrapper .modal-body-container .token-deposit-modal .mid-section .time-limit span em {
  font-size: 10px;
  color: #9C9E9F;
  font-style: normal;
  display: block;
  text-align: center;
  text-transform: uppercase;
}
.gold-token-wrapper .modal-body-container .token-deposit-modal .mid-section b,
.gold-token-wrapper .modal-body-container .token-deposit-modal .mid-section strong {
  width: 100%;
  align-items: center;
  justify-content: center;
  color: #1ECFAA;
  font-size: 16px;
  display: inline-flex;
}
.gold-token-wrapper .modal-body-container .token-deposit-modal .mid-section strong {
  color: #FFFFFF;
  font-size: 13px;
  font-weight: 400;
  margin-top: 15px;
}
.gold-token-wrapper .modal-body-container .token-deposit-modal .mid-section .qr-wrap {
  width: 215px;
  height: 215px;
  margin: 25px auto;
  display: inline-flex;
  position: relative;
}
.gold-token-wrapper .modal-body-container .token-deposit-modal .mid-section .qr-wrap::before,
.gold-token-wrapper .modal-body-container .token-deposit-modal .mid-section .qr-wrap::after {
  width: 20px;
  height: 20px;
  position: absolute;
  content: '';
  left: -10px;
  top: -10px;
  border-left: 1px solid #1ECFAA;
  border-top: 1px solid #1ECFAA;
}
.gold-token-wrapper .modal-body-container .token-deposit-modal .mid-section .qr-wrap::after {
  right: -10px;
  left: auto;
  border-left: 0px solid #1ECFAA;
  border-top: 0px solid #1ECFAA;
  border-right: 1px solid #1ECFAA;
  border-top: 1px solid #1ECFAA;
}
.gold-token-wrapper .modal-body-container .token-deposit-modal .mid-section .qr-wrap span::before,
.gold-token-wrapper .modal-body-container .token-deposit-modal .mid-section .qr-wrap span::after {
  width: 20px;
  height: 20px;
  position: absolute;
  content: '';
  left: -10px;
  bottom: -10px;
  border-left: 1px solid #1ECFAA;
  border-bottom: 1px solid #1ECFAA;
}
.gold-token-wrapper .modal-body-container .token-deposit-modal .mid-section .qr-wrap span::after {
  right: -10px;
  left: auto;
  border-left: 0px solid #1ECFAA;
  border-top: 0px solid #1ECFAA;
  border-right: 1px solid #1ECFAA;
  border-bottom: 1px solid #1ECFAA;
}
.gold-token-wrapper .modal-body-container .token-deposit-modal .mid-section .qr-wrap img {
  width: 100%;
  height: 100%;
}
.gold-token-wrapper .modal-body-container .token-deposit-modal .mid-section .deposit-bottom-section span {
  width: 100%;
  justify-content: center;
  color: #989898;
  font-size: 13px;
  display: inline-flex;
  margin: 5px 0;
}
.gold-token-wrapper .modal-body-container .token-deposit-modal .mid-section .deposit-bottom-section span em {
  color: #1ECFAA;
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
  position: relative;
  font-style: normal;
  margin-left: 10px;
  padding-left: 20px;
}
.gold-token-wrapper .modal-body-container .token-deposit-modal .mid-section .deposit-bottom-section span em::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 14px;
  height: 14px;
  background: url('/assets/images/copy.svg') no-repeat;
  background-size: 100%;
}
.gold-token-wrapper .modal-body-container .token-deposit-modal .mid-section .deposit-bottom-section strong {
  margin-top: 5px;
}
.gold-token-wrapper .modal-body-container .token-deposit-modal .mid-section .deposit-bottom-section button.button-btn-primary {
  display: block;
  margin: 25px auto 0;
  width: auto !important;
  border-radius: 25px;
  padding: 5px 40px;
}

.modal-container {
  background: rgba(0, 0, 0, 0.8) 0% 0% no-repeat padding-box;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99999;
}
.modal-container .modal-body-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #171717 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 50px #c39f421a;
  border: 1px solid #27251d;
  border-radius: 5px;
}
.modal-container .modal-body-container .close-icon {
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
}

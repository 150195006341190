.custom-datepicker.right-align .react-datepicker-popper {
  left: unset !important;
  right: 50px !important;
}
.custom-datepicker .react-datepicker__input-container input {
  border: 1px solid #333333;
  border-radius: 4px;
  background: #333333 0% 0% no-repeat padding-box;
  padding: 5px;
  font-size: 13px;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  padding-left: 12px;
  height: auto;
  color: #ffffff;
}
.custom-datepicker .react-datepicker__input-container input:focus {
  outline: none;
}
.custom-datepicker .react-datepicker__navigation {
  background-size: cover;
  width: 12px;
  height: 12px;
  top: 6px;
}
.custom-datepicker .react-datepicker__header {
  background-color: #ecf5fa;
  padding-top: 2px;
}
.custom-datepicker .react-datepicker__header .react-datepicker__current-month {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  color: #466e89;
}
.custom-datepicker .react-datepicker__day-name {
  font-size: 11px;
  color: #657480;
  margin: 0;
}
.custom-datepicker .react-datepicker__day {
  font-size: 11px;
  color: #657480;
  margin: 0;
  border-radius: 50% !important;
}
.custom-datepicker .react-datepicker__day:focus {
  outline: none;
}
.custom-datepicker .react-datepicker__day.react-datepicker__day--selected {
  background-color: #95e1f2;
  color: #2b516a;
}
.custom-datepicker .react-datepicker__day.react-datepicker__day--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.custom-datepicker .react-datepicker__day.react-datepicker__day--outside-month {
  opacity: 0.5;
}
.react-datepicker-wrapper {
  display: block !important;
}

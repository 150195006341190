@media only screen and (max-width:1200px) and (min-width: 1024px){
    .transaction-wrapper .transaction-section li:nth-of-type(3)::before, .transaction-wrapper .transaction-section li:nth-of-type(2)::before{
        left: 25px !important;
    }
    .transaction-wrapper .transaction-section li:nth-of-type(1)::before{
        left: 40px !important;
    }
    .gold-token-wrapper .top-section .left-section{
        width: 25%;
    }
    .gold-token-wrapper .top-section .right-section{
        width: 75%;
    }
    .gold-token-wrapper .mid-section .right-section .time-count{
        margin-bottom: 0;
    }
    .gold-token-wrapper .mid-section .right-section .time-count li{
        padding: 15px 10px;
        margin: 0 8px;
        width: 20%;
    }
    .gold-token-wrapper .mid-section .right-section .time-count-bottom .input-box{
        width: 100%;
    }
}
@media only screen and (max-width:1023px) and (min-width: 768px){
    .container{
        width: 100% !important;
        padding: 0 10px !important;
    }
    .main-heading{
        font-size: 36px;
    }
    .span-heading{
        font-size: 18px;
    }
    .main-content .main-search .Tabs .tabs-normal, .main-content .main-search .Tabs .tabs-active{
        font-size: 12px;
        padding: 8px;
    }
    .main-content .main-search .col-8.adjustRow .col-3{
        max-width: 40%;
        flex: 0 0 40%;
        margin-bottom: 15px;
    }
    .main-content .inner-content .main-search .exchange-btn {
        margin-top: 5px !important;
        margin-left: 0px !important;
    }
    .spec{
        display: block !important;
    }
    .spec .access{
        width: 50%;
        margin: 15px 0;
    }
    
    .spec .access:nth-of-type(2n) .coin-txt{
        border-right: 0;
    }
    .login-wrapper .col-6:nth-of-type(1){
        max-width: 60%;
        flex: 0 0 60%;
    }
    .login-wrapper .col-6:nth-of-type(2){
        max-width: 40%;
        flex: 0 0 40%;
    }
    .transaction-wrapper{
        max-width: 100% !important;
    }
    .transaction-wrapper .transaction-section li::before{
        left: 55px !important;
    }
    .transaction-wrapper .transaction-section li:nth-of-type(3)::before, .transaction-wrapper .transaction-section li:nth-of-type(2)::before{
        left: 35px !important;
    }
    .head-bg .logo img {
        width: 100% !important;
    }
    .head-bg .col-8 {
        justify-content: flex-end;
        max-width: 75% !important;
        flex: 0 0 75%;
    }
    .app-store-modal .modal-content ol li {
        font-size: 12px !important;
    }
    .app-store-modal .modal-content b{
        font-size: 14px !important;
    }
    .app-store-modal .video-wrapper{
        height: 200px !important;
    }
    .app-store-modal .modal-content strong{
        font-size: 14px !important;
    }
    .modal-container .modal-body-container.coming-soon-alert div .coming-soon-text{
        font-size: 14px !important;
    }
    .pro-trade .left-content-wrapper .left-content .order-list .head-section ul li{
        padding-right: 0px !important;
        font-size: 11px !important;
    }
    .pro-trade .left-content-wrapper .left-content .order-list .trade-content, .pro-trade .left-content-wrapper .left-content .order-list .portfolio-content{
        white-space: nowrap;
        overflow:auto;
    }
    .pro-trade .left-content-wrapper .left-content .order-list .head-section ul.inline-list.portfolio-list{
        display: none;
    }
    .pro-trade .left-content-wrapper .left-content .order-list .trade-content .trade-content-list span, .pro-trade .left-content-wrapper .left-content .order-list .portfolio-content .portfolio-content-list span{
        font-size: 11px !important;
        white-space: normal !important;
        text-overflow: inherit !important;
        min-width: 150px !important;
        max-width: 200px !important;
        width: auto !important;
    }
    .pro-trade .left-content-wrapper .left-content .order-list .trade-content .trade-content-list span:nth-of-type(1) i{
        height: 25px !important;
        width: 25px !important;
        padding: 3px 0px !important;
    }
    .pro-trade .left-content-wrapper .left-content .order-list .trade-content .trade-content-list span:nth-of-type(1) i img{
        width: 50%;
    }
    .pro-trade .col-9.left-content-wrapper, .pro-trade .col-3.right-content-wrapper{
        max-width: 100% !important;
        flex: 0 0 100% !important;
        padding-right: 0px !important;
    }
    .pro-trade .middle-content-wrapper .middle-content{
        padding: 5px !important;
    }
    .pro-trade .left-content-wrapper .left-content{
        padding: 10px 5px !important;
    }
    .Tabs .tabs-active, .Tabs .tabs-normal{
        font-size: 12px !important;
    }
    .pro-trade .middle-content-wrapper .middle-content .account-list li:nth-of-type(1){
        width: 48% !important;
    }
    .pro-trade .middle-content-wrapper .middle-content .account-list li:nth-of-type(1) span{
        padding-right: 0px !important;
        font-size: 11px !important;
    }
    .pro-trade .middle-content-wrapper .middle-content .account-list li:nth-of-type(1) b{
        font-size: 12px !important;
    }
    .header-login li{
        font-size: 11px !important;
        padding: 5px !important;
    }
    .header-login{
        margin-right: 20px !important;
    }
    .head-bg .right-head .head-txt button.button-btn-primary, .head-bg .right-head .head-txt .primary-btn{
        width: 80px !important;
        font-size: 11px !important;
        height: 30px !important;
        padding: 6px 15px;
    }
    .head-bg .right-head .head-txt label{
        margin-bottom: 0;
    }
    .head-bg .right-head .head-txt{
        display: inline-flex;
        align-items: center;
    }
    .header-login li span.prof-name{
        max-width: 60px !important;
        margin-right: 10px !important;
    }
    .header-login li .translation-tab .translation-tab-active, .header-login li .translation-tab .translation-tab-normal{
        font-size: 11px !important;
    }
    .head-bg .col-5.left-head .logo {
        display: inline-flex;
        width: 35% !important;
        align-items: center;
    }
    .head-bg .left-head .Tabs{
        width: 65% !important;
        display: inline-flex;
        align-items: center;
    }
    .head-bg .left-head .Tabs .adjustRow.tabs-border{
        width: 90% !important;
        margin-left: 10px;
    }
    .head-bg .left-head .Tabs .adjustRow.tabs-border .tabs-active, .head-bg .left-head .Tabs .adjustRow.tabs-border .tabs-normal{
        padding: 4px !important;
        font-size: 11px !important;
    }
    .pro-trade .col-9.left-content-wrapper:nth-of-type(even), .pro-trade .col-3.right-content-wrapper.trade-wrapper-list{
        margin-top: 15px;
    }
    .gold-token-wrapper .top-section .left-section{
        width: 30%;
        padding: 10px;
    }
    .gold-token-wrapper .top-section .right-section{
        width: 70%;
    }
    .gold-token-wrapper .mid-section .left-section{
        width: 55%;
    }
    .gold-token-wrapper .mid-section .right-section{
        width: 45%;
        max-height: 480px;
    }
    .gold-token-wrapper .mid-section .right-section .time-count li{
        width: 45%;
        margin: 10px 5px;
    }
    .gold-token-wrapper .mid-section .right-section strong{
        padding: 10px 0;
    }
    .gold-token-wrapper .mid-section .right-section .time-count{
        margin-bottom: 0;
    }
    .gold-token-wrapper .mid-section .right-section .time-count-bottom label{
        margin-bottom: 5px;
    }
    .gold-token-wrapper .mid-section .right-section .time-count-bottom .input-box{
        width: 100%;
        margin-bottom: 15px;
    }
    .gold-token-wrapper .mid-section .left-section .blockchain-list li{
        width: 100%;
        display: inline-flex;
    }
    .gold-token-wrapper .modal-body-container .token-deposit-modal .mid-section .qr-wrap{
        width: 100px;
        height: 100px;
    }
    .gold-token-wrapper .modal-body-container{
        width: 90%;
    }
    .gold-token-wrapper .modal-body-container .token-deposit-modal .top-section strong{
        width: 100%;
        font-size: 12px;
        margin-bottom: 10px;
    }
    .gold-token-wrapper .modal-body-container .token-deposit-modal .top-section span{
        width: 90%;
    }
    .gold-token-wrapper .modal-body-container .token-deposit-modal {
        padding: 10px;
    }

    .gold-token-wrapper .modal-body-container .token-deposit-modal .mid-section .deposit-bottom-section span, .gold-token-wrapper .modal-body-container .token-deposit-modal .mid-section .deposit-bottom-section span em{
        font-size: 11px;
    }
    .trade-section .gold-token button{
        height: 20px !important;
        width: 140px !important;
        font-size: 12px !important;
    }
    .trade-section .gold-token button span{
        width: 140px !important;
        top: 0px !important;
    }
    .trade-section .gold-token-mob {
        display: none !important;
    }
}

@media only screen and (max-width:579px){
   body{
       overflow-x: hidden;
   }
   .container{
       width: 100% !important;
       padding: 0 10px !important;
   }
   div.head-bg{
       padding: 10px 0;
       align-items: flex-start;
   }
   div.head-bg .col-5.left-head .logo img{
       width: 100% !important;
   }
   .head-txt label{
       font-size: 10px !important;
       margin-right: 10px;
   }
   .header-section .button-btn-primary, .header-section span.primary-btn{
    width: auto !important;
    height: auto !important;
    padding: 2px 6px !important;
    font-size: 9px !important;
   }
   .header-login{
    position: absolute;
    top: 35px;
    background: #12161d;
    margin-right: 0 !important;
    right: -15px;
    width: 100vw;
    justify-content: flex-end !important;
    border-top: 1px solid #1D232E;
   }
   .header-login li{
       font-size: 11px !important;
       padding: 5px 10px !important;
   }
   .header-login li .translation-tab{
       width: 120px !important;
   }
   .header-login li i{
       right: -5px;
   }
   div.head-bg .header-login li i img{
       width: 8px;
   }
   .header-login li span.prof-name{
       max-width: 55px !important;
   }
   .header-login li::after{
       display: none;
   }
   .transaction-wrapper{
       max-width: 100%;
       margin-bottom: 0 !important;
   }
   .transaction-wrapper .transaction-section{
       margin-bottom: 0px !important;
   }
   .transaction-wrapper .transaction-section li{
       width: 100% !important;
       margin-bottom: 15px;
       margin-right: 0 !important;
       padding: 20px !important;
       font-size: 14px !important;
   }
    .header-login  li:last-child{
       position: absolute;
       top: -35px;
       right: 85px;
   }
   .beforeLogin li:last-child{
       top: 0;
       right: 0;
       position: relative !important;
   }
   /*.header-login li:nth-of-type(1){
    position: static;
} */
   .main-heading{
       font-size: 30px;
   }
   .main-content{
       margin-top: 30px;
   }
   .main-content .span-heading{
       font-size: 12px;
       padding-right: 40px;
       margin: 5px 0;
   }
   .spec .access{
        border-bottom: 1px solid rgba(156, 158, 159, 0.07);
    }

   .main-content .main-search .col-8.adjustRow{
    max-width: 100%;
    flex: 0 0 100%;
    display: block;
   }
   .main-content .main-search .col-8.adjustRow .col-3{
       max-width: 100%;
       flex: 0 0 100%;
       margin-bottom: 15px;
   }
   .main-content .inner-content .main-search .swap-btn{
       margin-left: 0px !important;
       transform: rotate(90deg);
       margin-right: 0px !important;
       margin-top: 0px !important;
   }
   .main-content .inner-content .main-search .col-3 span{
       font-size: 12px !important;
       margin-bottom: 0px !important;
   }
   .main-content .main-search .Tabs .tabs-border{
       width: 200px;
   }
   .main-content .main-search .Tabs .tabs-normal, .main-content .main-search .Tabs .tabs-active{
       font-size: 10px;
       padding: 4px;
   }
   .main-content .inner-content .main-search .exchange-btn{
    width: auto !important;
    margin-left: 0 !important;
    margin-top: 10px !important;
    font-size: 11px;
    height: auto;
    padding: 5px;
   }
   .trade-section{
    padding-top: 0;
    margin-top: 30px;
   }
   .transaction-wrapper .transaction-section li:nth-of-type(3)::before{
       left: 65px !important;
   }
    .transaction-wrapper .transaction-section li:nth-of-type(2)::before{
        left: 65px !important;
    }
    .transaction-wrapper .transaction-section li:nth-of-type(1)::before{
        left: 85px !important;
    }
    .login-wrapper{
        display: inline-block;
        padding: 0 !important;
        width: 100%;
    }
    .login-wrapper .col-6{
        max-width: 100% !important;
        padding: 0;
    }
    .login-wrapper .left-wrapper{
        width: 100% !important;
        padding: 20px !important;
    }
    .modal-container .modal-body-container.home-alert{
        width: 80% !important;
    }
    .modal-container .modal-body-container.home-alert .alert-text{
        font-size: 14px !important;
        margin-bottom: 35px !important;
    }
    .home-icon{
        top: 15px !important;
        padding: 8px 10px !important;
    }
    .home-icon img{
        width: 70%;
    }
    .login-wrapper .container{
        display: flex;
        flex-direction: column-reverse;
    }
    .login-wrapper .left-wrapper{
        margin-top: 160px !important;
        margin-bottom: 30px !important;
    }
    .login-wrapper .right-wrapper .login-logo .main-logo{
        margin: 0% auto !important;
    }
    .id-verify-section{
        width: 100% !important;
        padding: 30px 10px !important;
        background: #171717;
    }
    .id-verify-section .inp-inner, .inp-inner.pd-inner{
        width: 100% !important;
        padding-right: 0 !important;
    }
    .id-verify-section .input-flx{
        display: block !important;
    }
    h4.coin-head{
        font-size: 16px !important;
    }
    .id-verf-lb label{
        font-size: 14px !important;
    }
    .spec{
        display: block !important;
        padding-bottom: inherit !important;
    }
    .spec .access{
        width: 100%;
        padding: 10px 0;
    }
    .spec .access:nth-of-type(1){
        padding-top: 0;
    }
    .spec .access .coin-txt{
        border-right: 0;
    }
    .coin-trade{
        padding: 20px 0;
        margin-bottom: 0px !important;
    }
    .news-section{
        padding-top: 0 !important;
    }
    .main-content .inner-content .stats-section{
        width: 55px !important;
        right: -15px !important;
        top: 20px !important;
    }
    .main-content .inner-content .stats-section .inner-stats{
        width: 55px !important;
        height: 110px !important;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }
    .stats-list li b, .stats-list li strong, .stats-list li em, .stats-list li.stats-total, .stats-list li.stats-accordion b, .stats-list li.stats-accordion .bal, .stats-list li.stats-accordion span{
        display: none !important;
    }
    .stats-list li{
        border-bottom: 0 !important;
        padding: 5px !important;
    }
    .stats-list{
        padding: 5px 10px !important;
    }
    .stats-list li.stats-accordion{
        box-shadow: none !important;
        padding: 5px 0px !important;
        border:0px !important;
        margin-top: 0px !important;
        text-align: center;
    }
    .main-content .inner-content .stats-section .left-arrow{
        left: -28px !important;
        top: 15px !important;
        width: 30px;
        padding: 8px !important;
    }
    .main-content .inner-content .stats-section .left-arrow img{
        width: 100% !important;
    }
    .main-content .inner-content .stats-section.expand{
        width: 270px !important;
    }
    .main-content .inner-content .stats-section.expand .inner-stats{
        width: 270px !important;
        height: 250px !important;
    }
    .stats-section.expand .inner-stats .stats-list li.stats-total, 
    .stats-section.expand .inner-stats .stats-list li.stats-total b, 
    .stats-section.expand .inner-stats .stats-list li.stats-accordion b, 
    .stats-section.expand .inner-stats .stats-list li.stats-accordion .bal, 
    .stats-section.expand .inner-stats .stats-list li.stats-accordion span,
    .stats-section.expand .inner-stats .stats-list li b, .stats-section.expand .inner-stats .stats-list li strong{
        display: inline-block !important;
        vertical-align: middle;
    }
    .main-content .inner-content .stats-section .inner-stats .stats-list li.stats-total b{
        width: 35% !important;
        font-size: 14px;
        text-align: left !important;
        padding-left: 30px !important;
    }
    .stats-section.expand .inner-stats .stats-list li.stats-accordion{
        box-shadow: inherit !important;
        text-align: left;
        border: 1px solid #222222 !important;
        box-shadow: 0px 0px 6px #00000099 !important;
        border-radius: 5px !important;
        margin-top: 10px !important;
    }
    .stats-section.expand .inner-stats .stats-list li{
        width: 100%;
    }
    .popover-content.header-dropdown{
        width: 300px !important;
    }
    .main-content .inner-content .stats-section .left-arrow{
        display: block !important;
    }
    .main-content .inner-content .stats-section.expand .inner-stats .stats-list li.stats-accordion .accordion-content .content-left,
    .main-content .inner-content .stats-section.expand .inner-stats .stats-list li.stats-accordion .accordion-content .content-right
    {
        width: 100% !important;
    }
    .main-content .inner-content .stats-section.expand .inner-stats .stats-list li b{
        width: 85% !important;
    }
    .main-content .inner-content .stats-section.expand .inner-stats .stats-list li.stats-accordion .bal{
        width: 30% !important;
        padding: 0 10px;
        margin-bottom: 10px;
    }
    .main-content .inner-content .stats-section.expand .inner-stats .stats-list li.stats-accordion .accordion-content .content-left span{
        font-size: 10px;
    }
    .app-store-modal{
        width: 90% !important;
    }
    .app-store-modal .modal-content strong{
        font-size: 14px !important;
    }
    .app-store-modal .video-wrapper{
        height: 200px !important;
    }
    .app-store-modal .video-wrapper img{
        width: 100%;
        height: 100%;
    }
    .app-store-modal .modal-content ol li {
        font-size: 12px !important;
    }
    .app-store-modal .modal-content b{
        font-size: 14px !important;
    }
    .modal-container .modal-body-container.coming-soon-alert{
        width: 85% !important;
    }
    .modal-container .modal-body-container.coming-soon-alert div .coming-soon-text{
        font-size: 14px !important;
    }
    .custom-dropdown .custom-dropdown-toggle .dropdown-selected input{
        font-size: 11px !important;
    }
    .Toastify__toast-body span.message{
        font-size: 11px !important;
        padding: 5px !important;
      }
      /*protrade css*/
      .pro-trade .col-9, .pro-trade .col-3{
         width: 100%;
         flex: 0 0 100%;
         max-width: 100%;
      }
      .pro-trade .pad-l-0, .pro-trade .pad-r-0{
          padding: 0px !important;
      }
      .pro-trade .middle-content-wrapper .middle-content .account-list{
          display: inline-block !important;
      }
      .pro-trade .middle-content-wrapper .middle-content .account-list li{
          width: 50% !important;
          margin-bottom: 15px;
      }
      .pro-trade .middle-content-wrapper .middle-content .account-list li:nth-of-type(1){
          width: 100% !important;
      }
      .pro-trade .left-content-wrapper .left-content.left-search, .pro-trade .right-content-wrapper, .pro-trade .left-content-wrapper{
          margin-bottom: 20px;
      }
      .content-wrapper{
          padding-top:0px;
      }
      .pro-trade .left-content-wrapper .left-content .order-list .head-section ul li{
          padding-right: 0px !important;
          font-size: 11px !important;
      }
      .pro-trade .left-content-wrapper .left-content .order-list .trade-content, .pro-trade .left-content-wrapper .left-content .order-list .portfolio-content{
          white-space: nowrap;
          overflow:auto;
      }
      .pro-trade .left-content-wrapper .left-content .order-list .head-section ul.inline-list.portfolio-list{
          display: none;
      }
      .pro-trade .left-content-wrapper .left-content .order-list .trade-content .trade-content-list span, .pro-trade .left-content-wrapper .left-content .order-list .portfolio-content .portfolio-content-list span{
          font-size: 11px !important;
          white-space: normal !important;
          text-overflow: inherit !important;
          min-width: 120px !important;
          max-width: 200px !important;
          width: auto !important;
      }
      .pro-trade .left-content-wrapper .left-content .order-list .trade-content .trade-content-list span:nth-of-type(1) i{
          height: 25px !important;
          width: 25px !important;
          padding: 3px 0px !important;
      }
      .pro-trade .left-content-wrapper .left-content .order-list .trade-content .trade-content-list span:nth-of-type(1) i img{
          width: 50%;
      }
      .pro-trade .left-content-wrapper .left-content .order-list .head-section button.export-btn{
          right: 5px !important;
          top: 5px !important;
      }

     .head-bg .left-head .Tabs .adjustRow{
          z-index: 1;
          position: relative;
          left: 0;
          top: 17px;
          width: 150px !important;
        }
        .head-bg .left-head .Tabs .tabs-normal{
          font-size: 11px !important;
          padding: 4px 0px;
        }
        .head-bg  .left-head .Tabs  .tabs-active{
          font-size: 11px !important;
          padding: 4px 3px;
        }
        .head-bg .col-5.left-head .logo{
            width: 80% !important;
        }
        .apikey .coin-trade.header-coin, .address-book .coin-trade.header-coin{
            padding: 20px 10px !important;
        }
        .apikey .addr-book-sec, .address-book .addr-book-sec{
            margin: 10px !important;
        }
        .apikey .api-top{
            padding: 10px !important;
        }
        .apikey .api-top p{
            font-size: 12px !important;
        }
        .apikey .coin-trade.header-coin button.add-key, .address-book .coin-trade button.button-btn-secondary{
            font-size: 12px !important;
            width: 140px !important;
        }
        .address-book .coin-trade button.button-btn-secondary.add-key{
            width: 170px !important;
        }
        .apikey table.addr-head, .apikey table.addr-content{
            white-space: nowrap;
            overflow: auto;
        }
        .apikey table.addr-head th:nth-child(1), .apikey table.addr-content td:nth-child(1){
            width: 280px !important;
        }
        .apikey table.addr-head th:nth-child(2), .apikey table.addr-content td:nth-child(2){
            width: 180px !important;
        }
        .apikey table.addr-head th:nth-child(3), .apikey table.addr-content td:nth-child(3){
            width: 180px !important;
        }
        .apikey table.addr-head th:nth-child(4), .apikey table.addr-content td:nth-child(4){
            width: 180px !important;
        }
        .apikey table.addr-head{
            background: #272727;
            position: sticky;
            top: 0;
            z-index: 1;
        }
        .modal-container .modal-body-container.add-api-key{
            min-width: 90% !important;
            max-width: 90% !important;
        }
        .addr-table-sec{
            white-space: nowrap;
            overflow: auto;
        }
        .apikey .scroll-tbl{
            width: auto;
            display: inline-block;
        }
        .modal-container .modal-body-container.add-api-key .modal-content{
            padding: 20px 10px !important;
        }
        .modal-container .modal-body-container.add-api-key .modal-content .inline-list.tab-list li:last-child{
            margin-right: 0 !important;
        }
        .tags-wrapper .tag-list-content span{
            margin-bottom: 5px;
        }
        .gold-token-wrapper .top-section{
            margin-top: 50px;
        }
        .gold-token-wrapper .top-section .right-section{
            width: 100%;
            margin-top: 20px;
            padding: 0;
        }
        .gold-token-wrapper .mid-section .left-section, .gold-token-wrapper .mid-section .right-section{
            width: 100%;
            padding-right: 0;
            padding-left: 0;
            max-height: inherit;
        }
        .gold-token-wrapper .top-section{
            flex-wrap: wrap;
        }
        .gold-token-wrapper .top-section .left-section{
            width: 80%;
        }
        .gold-token-wrapper .top-section .left-section button.download-btn{
            padding: 10px 10px 10px 35px;
        }
        .gold-token-wrapper .top-section .right-section .inline-list li{
            width: 100%;
            padding-bottom: 15px;
        }
        .gold-token-wrapper .mid-section .left-section .blockchain-list li{
            width: 100%;
            display: inline-flex;
        }
        .gold-token-wrapper .mid-section .left-section .blockchain-list li strong{
            width: 50%;
        }
        .gold-token-wrapper .mid-section .right-section .time-count{
            width: 75%;
            margin: 0 auto;
            display: block;
        }
        .gold-token-wrapper .mid-section .right-section strong{
            padding: 15px 0;
        }
        .gold-token-wrapper .mid-section .right-section .time-count li{
            margin: 10px 5px;
            width: 45%;
        }
        .gold-token-wrapper .mid-section .right-section .time-count-bottom{
            padding-left: 10px;
        }
        .gold-token-wrapper .mid-section .right-section .time-count-bottom label{
            font-size: 12px;
            margin-bottom: 5px;
        }
        .gold-token-wrapper .mid-section .right-section .time-count-bottom .input-box{
            width: 100%;
        }
        .gold-token-wrapper .mid-section .right-section .time-count-bottom .button-btn-primary{
            margin-top: 15px;
        }
        .gold-token-wrapper .modal-body-container .token-deposit-modal .mid-section .qr-wrap{
            width: 100px;
            height: 100px;
            margin: 25px;
        }
        .gold-token-wrapper .modal-body-container{
            width: 90%;
        }
        .gold-token-wrapper .modal-body-container .token-deposit-modal .top-section strong{
            width: 100%;
            font-size: 12px;
            margin-bottom: 10px;
        }
        .gold-token-wrapper .modal-body-container .token-deposit-modal .top-section span{
            width: 90%;
        }
        .gold-token-wrapper .modal-body-container .token-deposit-modal {
            padding: 10px;
        }
        .gold-token-wrapper .modal-body-container .token-deposit-modal .mid-section .time-limit{
            top: 100px;
        }
        .gold-token-wrapper .modal-body-container .token-deposit-modal .mid-section .deposit-bottom-section span, .gold-token-wrapper .modal-body-container .token-deposit-modal .mid-section .deposit-bottom-section span em{
            font-size: 11px;
        }
        .trade-section .gold-token-mob{
            display: block !important;
        }
        .trade-section .gold-token{
            display: none;
        }
}

@media (min-width:380px) and (max-width: 578px){
    .login-wrapper .left-wrapper{
        margin-top: 210px !important;
    }
    .gold-token-wrapper .top-section .left-section{
        width: 60%;
    }
}

@media  (min-width: 480px) and (max-width:1024px) and (orientation: landscape) {
    .main-content .inner-content .stats-section .inner-stats{
        height: calc(100vh - 170px) !important;
    }
    body.hidden {
        position: relative;
        overflow: visible;
    }
}

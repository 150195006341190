.header-section {
  background: #12161d;
}
.head-bg {
  padding: 15px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.head-bg .col-5.left-head .logo {
  display: inline-flex;
  width: 50%;
}
.head-bg .col-5.left-head .logo img {
  width: 80%;
}
.head-bg .col-7 {
  justify-content: flex-end;
}
.head-txt label {
  color: #fff;
  font-size: 13px;
  margin-right: 15px;
}
.head-txt label span {
  cursor: pointer;
}
.head-txt label span.primary-btn {
  background-color: #191919;
  color: #C39F42;
  font-size: 14px;
  cursor: pointer;
  height: 40px;
  font-family: 'noto_sansmedium';
  padding: 8px 25px;
  border-radius: 50px;
  border: 1px solid #C39F42;
}
.head-txt label span.primary-btn:hover {
  background: #C39F42;
  color: #ffffff;
}
.popover-content {
  background: #12161d 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 30px #c39f4214;
  border: 1px solid #52525299;
  width: 130px;
  border-radius: 5px;
  position: relative;
  margin-top: 9px;
}
.popover-content .popover-item {
  padding-top: 15px;
  padding-left: 15px;
  cursor: pointer;
}
.popover-content .popover-item a {
  color: #9c9e9f;
  font-family: "noto_sansmedium";
  font-size: 12px;
  margin-left: 6px;
  text-decoration: none;
}
.popover-content .popover-item a:hover {
  text-decoration: underline;
}
.popover-content .popover-item:last-child {
  padding-bottom: 10px;
}
.popover-content::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  background: #12161d 0% 0% no-repeat padding-box;
  border-left: 1px solid #52525299;
  border-top: 1px solid #52525299;
  top: -6px;
  left: calc(50% + 10px);
  transform: rotate(45deg);
}
.app-store-modal {
  width: 50%;
}
.header-login {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  margin-right: 30px;
}
.header-login li {
  font-size: 13px;
  color: #9C9E9F;
  padding: 5px 15px;
  position: relative;
  cursor: pointer;
}
.header-login li::after {
  content: '';
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0;
  height: 32px;
  width: 1px;
  margin: auto;
  background: #383A3D;
}
.header-login li:last-child::after {
  right: -15px;
}
.header-login li span.prof-img {
  margin-right: 5px;
  background: #0B0E11;
  border: 1px solid #272D38;
  padding: 5px 8px;
  border-radius: 100%;
}
.header-login li span.prof-img img {
  width: 14px;
}
.header-login li span.prof-name {
  max-width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.header-login li .translation-tab {
  width: 150px;
}
.header-login li .translation-tab .translation-tab-normal {
  padding: 4px;
}
.header-login li .translation-tab .translation-tab-active {
  font-size: 11px;
  padding: 4px;
}
.header-login li i {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 18px;
}
.header-login li i img {
  width: 12px;
}
.app-store-modal {
  width: 45%;
  padding: 15px 20px;
}
.app-store-modal .video-wrapper {
  width: 100%;
  height: 300px;
}
.app-store-modal .video-wrapper img {
  object-fit: cover;
  position: static !important;
  width: 100% !important;
}
.app-store-modal .video-wrapper video {
  width: 100%;
  height: 100%;
}
.app-store-modal .modal-content {
  background: transparent !important;
  border: 0px !important;
  color: #717377;
  padding: 10px 0 !important;
}
.app-store-modal .modal-content strong {
  font-size: 22px;
  padding: 5px 0;
}
.app-store-modal .modal-content b {
  padding: 5px 0;
  font-size: 18px;
  font-family: "noto_sansmedium";
}
.app-store-modal .modal-content ol {
  padding: 10px 20px;
}
.app-store-modal .modal-content ol li {
  font-size: 15px;
  padding-bottom: 10px;
}
.app-store-modal .modal-content ol li:last-child {
  padding-bottom: 0;
}
.app-store-modal .modal-content button {
  width: 180px !important;
}
.app-store-modal.modal-body-container img.close-icon {
  right: 5px !important;
  width: 9px !important;
  cursor: pointer;
}
.popover-content.header-dropdown {
  width: 340px;
  padding: 15px 15px 0px;
  z-index: 4;
  margin: 15px auto;
}
.popover-content.header-dropdown::before {
  left: calc(50% - 1px);
}
.popover-content.header-dropdown.inline-list li {
  padding: 15px 0;
  display: block;
  font-size: 12px;
  position: relative;
}
.popover-content.header-dropdown.inline-list li::after {
  display: none;
}
.popover-content.header-dropdown.inline-list li:nth-of-type(1),
.popover-content.header-dropdown.inline-list li:nth-of-type(2) {
  padding: 5px 0;
}
.popover-content.header-dropdown.inline-list li:nth-of-type(2) {
  padding-bottom: 15px;
}
.popover-content.header-dropdown.inline-list li b {
  color: #C39F42;
  font-weight: 400;
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}
.popover-content.header-dropdown.inline-list li i {
  position: absolute;
  right: 10px;
  top: 15px;
  height: 20px;
}
.popover-content.header-dropdown.inline-list li i img {
  width: 12px;
  transform: rotate(-90deg);
}
.popover-content.header-dropdown.inline-list li span {
  font-size: 13px;
  color: #B5B5B5;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  max-width: 80%;
}
.popover-content.header-dropdown.inline-list li em.not-verified {
  background: #BE2718;
  border: 1px solid #BE2718;
  border-radius: 30px;
  color: #fff;
  display: block;
  font-size: 10px;
  padding: 5px 10px 5px 30px;
  margin: 8px 0 0;
  position: relative;
  font-style: normal;
  cursor: default;
}
.popover-content.header-dropdown.inline-list li em.not-verified::before {
  content: '';
  position: absolute;
  left: 5px;
  top: 4px;
  width: 18px;
  height: 18px;
  background: url(/assets/images/warning.svg) no-repeat;
  background-size: 100%;
}
.popover-content.header-dropdown.inline-list li em.not-verified i {
  top: 3px;
}
.popover-content.header-dropdown.inline-list li em.not-verified i img {
  width: 8px;
}
.popover-content.header-dropdown.inline-list li em.verified {
  background: #008F11;
  border: 1px solid #008F11;
  border-radius: 30px;
  color: #fff;
  display: block;
  font-size: 10px;
  padding: 5px 10px 5px 30px;
  margin: 8px 0 0;
  position: relative;
  font-style: normal;
  cursor: default;
}
.popover-content.header-dropdown.inline-list li em.verified::before {
  content: '';
  position: absolute;
  left: 5px;
  top: 4px;
  width: 18px;
  height: 18px;
  background: url(/assets/images/warning.svg) no-repeat;
  background-size: 100%;
}
.popover-content.header-dropdown.inline-list li em.verified i {
  top: 3px;
}
.popover-content.header-dropdown.inline-list li em.verified i img {
  width: 8px;
}
.popover-content.header-dropdown .auth-list {
  padding-left: 0;
}
.popover-content.header-dropdown .auth-list li {
  padding: 5px 0;
}
.popover-content.header-dropdown .auth-list li:nth-of-type(2) {
  padding-bottom: 5px;
}

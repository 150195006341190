@media only screen and (max-width:1200px) and (min-width: 1024px) {
    .container {
        max-width: 1020px;
        width    : 1020px;
    }

    /* google authentication start */
    .row.mr-wdth {
        width: 95% !important;
    }

    .qr-section {
        text-align: center !important;
    }

    /* google authentication end */
}

@media only screen and (max-width:991px) {}

@media only screen and (max-width:768px) {

    .home-icon {
        padding      : 10px !important;
        padding-right: 13px !important;
        z-index      : 1 !important;
    }

    .home-icon img {
        height: 15px;
        width : 15px;
    }

    /* id-verification page start */
    .id-verify-section {
        padding: 30px !important;
        width  : 90% !important;
    }

    .idVerification .mob-select .main-drop .inputdropdown-ddbox {
        width: 35% !important;
    }

    /* id-verification page end */

    /* change pwd start */
    .login-wrapper .left-wrapper.mob-wd-wrapper {
        width : 80%;
        margin: 0 auto !important;
    }

    /* change pwd end */

    /* google authentication start */
    .inp-inner.password.mr-tp.tab-wd {
        width: 80% !important;
    }

    .copy-clipboard.tab-img img {
        right: -9px;
    }

    /* google authentication end */

    /* add account start */
    .id-verify-section.resp-pd {
        padding: 0 !important;
    }

    .bankAccount .overall-bnk {
        margin: 0px 20px;
    }

    /* add account end */
    .id-verify-section.tab-verif {
        padding       : 30px 25px !important;
        padding-bottom: 30px !important;
        width         : 100% !important;
    }

    /* history start */
    .lastTransaction .tab-section {
        width: 94%!important;
    }
    .lastTransaction ul.ul-transact-amt {
        margin: 25px 20px!important;
    }
    .lastTransaction .trans-up, .lastTransaction .trans-down{
        font-size: 11px!important;
    }
    /* history end */

}

@media only screen and (max-width:767px) {

    /* change pwd start */
    .id-verify-section.change-pwd-mob {
        padding: 10px !important;
        width  : 100% !important;
    }

    .coin-trade.mob-mr {
        padding: 15px 0;
    }

    /* change pwd end */

    /* google authentication start */
    .row.mr-wdth.mob-wd {
        width: 100%;
    }

    .secret-key {
        display   : block !important;
        margin-top: 15px;
    }

    .light-txt.mr-txt.mob-txt {
        margin-bottom: 15px;
        width        : 90%;
    }

    .inp-inner.password.mr-tp.tab-wd.mob-wd {
        width: 100% !important;
    }

    .copy-clipboard.tab-img.mob-img img {
        right: 15px;
    }

    /* google authentication end */

    /* add account start */
    .bank-input-sec {
        width: 100% !important;
    }

    .conf-btn {
        width: 95%;
    }

    /* add account end */

    /* choose-transaction start */
    .id-verify-section.mob-verif {
        padding       : 12px !important;
        padding-bottom: 30px !important;
        width         : 100% !important;
    }
    .container.chooseTransaction{
        padding: 0px 5px !important;
    }
    .chooseTransaction .trans-li{
        padding: 10px 6px!important;
    }
    .chooseTransaction .light-txt{
        font-size: 11px!important;
    }
    .chooseTransaction .trans-str{
        font-size: 12px!important;
    }
    .wd-coin-logo.mb-btm {
        margin-bottom: 10px!important;
        width        : 70%!important;
        text-align: left;
    }

    .trans-li.mob-li {
        flex-wrap  : wrap;
        align-items: center;
        position: relative;
    }

    .wd-mob,
    .wd-mob-nbr {
        display    : flex !important;
        align-items: center !important;
        position   : absolute !important;
        top        : 15px !important;
        right      : 12% !important;
    }
    .wd-mob.mb-top{
        top        : 3px !important;
    }

    .wd-mob-nbr {
        right: 40% !important;
        width: 20%!important;
    }

    .mob-al-low,.wd-balance.mob-draw{
        width       : 33.3% !important;
        flex-wrap   : wrap !important;
        display     : flex !important;
        padding-left: 35px !important;
        
    }
    .wd-balance.mob-draw{
        width: 34.3% !important;
        justify-content: center!important;
        padding-left: 0!important;
    }
    .withdraw-section .id-verify-section{
        padding: 0px !important;
        width: 100% !important;
    }

    .chooseTransaction .coin-trade.flx-trade.mob-blk {
        display: block !important;
    }

    .chooseTransaction .coin-trade.flx-trade h4.coin-head.mob-head:before {
        margin: auto !important;
    }

    .chooseTransaction .form-wrap.password.mob-search {
        margin-top: 20px;
        width     : 100%;
    }

    /* choose-transaction end */

    /* deposit page start */
    .inner-deposit {
        padding: 0px 10px;
    }

    .Deposit .dep-lb.mob-dep {
        margin    : 0;
        margin-top: 20px;
    }
    .Deposit .dep-lb.wire-txt.mob-dep {
        margin-bottom: 15px;
    }

    .overall-btc {
        width    : 100% !important;
        flex-wrap: wrap;
        position: relative;
    }

    .Deposit .dep-p.mob-txt {
        width    : 100%;
        font-size: 12px!important;
    }
    .Deposit .draw-ip,.withDraw .draw-ip{
        width: 90%!important;
    }
    .coin-trade.header-coin.mb-coin {
        padding: 12px 22px!important;
    }

    .mob-al-low.mb-pd {
        padding-left: 0px !important;
    }
    .Deposit .trans-str{
        text-align: left;
    }
    label.hash-code {
        padding   : 0px 10px;
        width     : 100%;
        word-break: break-all;
        text-align: center;
    }
    .draw-ip.mob-wd{
        width: 90%;
    }

    /* deposit page end */

    /* history start */
    .lastTransaction .flx-hist {
        flex-wrap: wrap!important;
    }
    .lastTransaction .wd-coin-logo,.lastTransaction .wd-hist,.lastTransaction .wd-comp,.lastTransaction .wd-hist {
        width: 50%!important;
    }
    .mob-pd.id-verify-section.hist-pd-none,.mob-last-mr.id-verify-section.last-transaction{
        width: 100% !important;
    }
    .lastTransaction .tab-section {
        display: block!important;
        margin-top: 15px!important;
    }
    .lastTransaction label.tb-lb {
        margin-bottom: 10px!important;
    }
    .lastTransaction .light-txt,.lastTransaction .fnt-trans{
        font-size:11px!important;
        
    }
    .lastTransaction .wd-comp {
        padding-left: 40px!important;
    }
    .lastTransaction .transact-amt {
        padding: 10px 0px!important;
    }
    .lastTransaction p.drp-hash{
        min-height: 30px!important; 
    }
    .lastTransaction .address-li, .lastTransaction .tax-li,.lastTransaction .transact-li,.lastTransaction .head-one, .lastTransaction .flx-trns {
        width: 100%!important;
    }
    .lastTransaction .coin-trade{
        display:block!important;
        padding: 15px!important;
    }
    .lastTransaction a.drp-hash{
        word-break: break-all;
        white-space: normal!important;
    }
    .lastTransaction .flx-trns{
        flex-flow: wrap!important;
        justify-content: flex-start!important;
        margin-top: 10px!important;
    }
    .lastTransaction .sell-btn {
        margin-top: 13px!important;
        margin-left: auto!important;
    }
    /* history end */

    /* withdraw start */
    .id-verify-section.wd-draw-pd,.id-verify-section.deposit-pd,.id-verify-section.transfer-pd{
        width: 100% !important;
    }
    .withdraw-section .inner-withdraw.wd-draw-mob {
        margin-top: 15px;
    }
    /* withdraw end */
    
    .transfer .draw-ip {
        width: 90% !important;
    }
    .transfer .flx-hist.mob-trans-fnt .light-txt{
        width: 100%!important;
        padding-left: 42px!important;
    }
    .transfer .wd-coin-logo{
        flex-wrap: wrap!important;
    }

    

}

@media only screen and (max-width:537px) and (min-width: 320px) {
    .mob-mr-btm {
        margin-bottom: 15px !important;
    }

    .bankAccount .overall-bnk.mob-bnk {
        margin-top: 10px!important;
        margin    : 0px 20px;
    }

    .id-verify-section.mob-id-verf {
        padding: 15px !important;
        width  : 100% !important;
    }

    /* confirm transaction start */
    .id-verify-section.mob-confirm {
        padding      : 12px 20px !important;
        width        : 100% !important;
        margin-bottom: 25px !important;
    }

    .conf-btn.mob-mr-btm {
        margin-bottom: 10px;
    }

    /* confirm transaction end */

    /* google authentication start */
    .app-section {
        justify-content: space-evenly;
        margin-bottom  : 10px;
    }

    label.app-fnt {
        margin-right: 0;
    }

    .qr-code {
        padding: 0px 20px;
    }

    s.border-qr {
        z-index: 1 !important;
    }

    /* google authentication end */

    /* history start */
    .lastTransaction .Tabs.tab-transact .primary-normal, .lastTransaction .Tabs.tab-transact .primary-active {
        padding: 5px 15px!important;
        font-size: 10px!important;
        overflow: hidden;
    }
    .lastTransaction ul.ul-transact-amt.mob-mr-none {
        margin: 25px 10px!important;
    }
    .lastTransaction .inner-dropdown ul{
        display:block!important;
    }
    .lastTransaction .wd-hist{
        text-align:center!important;
    }
    /* history end */

    .Deposit .trans-str,.withDraw .trans-str,.transfer .trans-str{
        font-size: 10px!important;
    }
    .Deposit .light-txt,.transfer .light-txt,.withDraw .light-txt,.transfer .light-txt,.transfer .trans-up, .transfer .trans-down,.withDraw .trans-up, .withDraw .trans-down,.Deposit .trans-up, .Deposit .trans-down{
        font-size: 11px!important;
    }
    
    
}

@media only screen and (max-width:320px) {

    .wd-balance.mob-al-low,
    .wd-coins.mob-al-low,
    .wd-price.mob-al-low {
        padding-left: 10px !important;
    }

    .under-btn.mob-mid {
        width       : 80%;
        margin      : 30px auto;
        margin-right: 20px;
    }
    .lastTransaction .wd-comp.mob-pd {
        padding-left: 0px!important;
    }
    .lastTransaction .inner-dropdown ul{
        padding-left: 6px!important;
    }
}
@font-face {
  font-family: "noto_sansbold";
  src: url("assets/fonts/notosans-bold-webfont.woff2") format("woff2"),
    url("assets/fonts/notosans-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "noto_sanslight";
  src: url("assets/fonts/notosans-light-webfont.woff2") format("woff2"),
    url("assets/fonts/notosans-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "noto_sansmedium";
  src: url("assets/fonts/notosans-medium-webfont.woff2") format("woff2"),
    url("assets/fonts/notosans-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "noto_sansregular";
  src: url("assets/fonts/notosans-regular-webfont.woff2") format("woff2"),
    url("assets/fonts/notosans-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: "noto_sanslight";
  background: #0b0e11;
}

::before,
::after,
* {
  box-sizing: border-box;
}
.col-1 {
  -ms-flex: 0 0 8.333333%;
  -webkit-box-flex: 0;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
  display: inline-flex;
  flex-wrap: wrap;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  -webkit-box-flex: 0;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
  display: inline-flex;
  flex-wrap: wrap;
}

.col-3 {
  -ms-flex: 0 0 25%;
  -webkit-box-flex: 0;
  flex: 0 0 25%;
  max-width: 25%;
  display: inline-flex;
  flex-wrap: wrap;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  -webkit-box-flex: 0;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  display: inline-flex;
  flex-wrap: wrap;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  -webkit-box-flex: 0;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
  display: inline-flex;
  flex-wrap: wrap;
}

.col-6 {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
  display: inline-flex;
  flex-wrap: wrap;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  -webkit-box-flex: 0;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
  display: inline-flex;
  flex-wrap: wrap;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  -webkit-box-flex: 0;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
  display: inline-flex;
  flex-wrap: wrap;
}

.col-9 {
  -ms-flex: 0 0 75%;
  -webkit-box-flex: 0;
  flex: 0 0 75%;
  max-width: 75%;
  display: inline-flex;
  flex-wrap: wrap;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  -webkit-box-flex: 0;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
  display: inline-flex;
  flex-wrap: wrap;
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  -webkit-box-flex: 0;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
  display: inline-flex;
  flex-wrap: wrap;
}

.col-12 {
  -ms-flex: 0 0 100%;
  -webkit-box-flex: 0;
  flex: 0 0 100%;
  max-width: 100%;
}

.pad-0 {
  padding: 0;
}

.pad-05 {
  padding: 5px;
}

.pad-10 {
  padding: 10px;
}

.pad-15 {
  padding: 15px;
}

.pad-20 {
  padding: 20px;
}

.pad-25 {
  padding: 25px;
}

.pad-30 {
  padding: 30px;
}

.pad-b-05 {
  padding-bottom: 5px;
}

.pad-b-10 {
  padding-bottom: 10px;
}

.pad-b-15 {
  padding-bottom: 15px;
}

.pad-b-20 {
  padding-bottom: 20px;
}

.pad-b-25 {
  padding-bottom: 25px;
}

.pad-b-30 {
  padding-bottom: 30px;
}

.pad-t-05 {
  padding-top: 5px;
}

.pad-t-10 {
  padding-top: 10px;
}

.pad-t-15 {
  padding-top: 15px;
}

.pad-t-20 {
  padding-top: 20px;
}

.pad-t-25 {
  padding-top: 25px;
}

.pad-t-30 {
  padding-top: 30px;
}

.pad-r-05 {
  padding-right: 5px;
}

.pad-r-10 {
  padding-right: 10px;
}

.pad-r-15 {
  padding-right: 15px;
}

.pad-r-20 {
  padding-right: 20px;
}

.pad-r-25 {
  padding-right: 25px;
}

.pad-r-30 {
  padding-right: 30px;
}

.pad-l-05 {
  padding-left: 5px;
}

.pad-l-10 {
  padding-left: 10px;
}

.pad-l-15 {
  padding-left: 15px;
}

.pad-l-20 {
  padding-left: 20px;
}

.pad-l-25 {
  padding-left: 25px;
}

.pad-l-30 {
  padding-left: 30px;
}
.pad-l-0 {
  padding-left: 0;
}
.pad-r-0 {
  padding-right: 0;
}
.pad-lr-05 {
  padding-left: 5px;
  padding-right: 5px;
}

.pad-lr-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.pad-lr-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.pad-lr-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.pad-lr-25 {
  padding-left: 25px;
  padding-right: 25px;
}

.pad-lr-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.pad-tb-05 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.pad-tb-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pad-tb-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.pad-tb-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pad-tb-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.pad-tb-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.mar-0 {
  margin: 0;
}

.mar-05 {
  margin: 5px;
}

.mar-10 {
  margin: 10px;
}

.mar-15 {
  margin: 15px;
}

.mar-20 {
  margin: 20px;
}

.mar-25 {
  margin: 25px;
}

.mar-30 {
  margin: 30px;
}

.mar-b-05 {
  margin-bottom: 5px;
}

.mar-b-10 {
  margin-bottom: 10px;
}

.mar-b-15 {
  margin-bottom: 15px;
}

.mar-b-20 {
  margin-bottom: 20px;
}

.mar-b-25 {
  margin-bottom: 25px;
}

.mar-b-30 {
  margin-bottom: 30px;
}

.mar-t-05 {
  margin-top: 5px;
}

.mar-t-10 {
  margin-top: 10px;
}

.mar-t-15 {
  margin-top: 15px;
}

.mar-t-20 {
  margin-top: 20px;
}

.mar-t-25 {
  margin-top: 25px;
}

.mar-t-30 {
  margin-top: 30px;
}

.mar-r-05 {
  margin-right: 5px;
}

.mar-r-10 {
  margin-right: 10px;
}

.mar-r-15 {
  margin-right: 15px;
}

.mar-r-20 {
  margin-right: 20px;
}

.mar-r-25 {
  margin-right: 25px;
}

.mar-r-30 {
  margin-right: 30px;
}

.mar-l-05 {
  margin-left: 5px;
}

.mar-l-10 {
  margin-left: 10px;
}

.mar-l-15 {
  margin-left: 15px;
}

.mar-l-20 {
  margin-left: 20px;
}

.mar-l-25 {
  margin-left: 25px;
}

.mar-l-30 {
  margin-left: 30px;
}

.mar-lr-05 {
  margin-left: 5px;
  margin-right: 5px;
}

.mar-lr-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mar-lr-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.mar-lr-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.mar-lr-25 {
  margin-left: 25px;
  margin-right: 25px;
}

.mar-lr-30 {
  margin-left: 30px;
  margin-right: 30px;
}

.mar-tb-05 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.mar-tb-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mar-tb-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mar-tb-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mar-tb-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.mar-tb-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.inline-list {
  padding: 0;
  font-size: 0;
}

.inline-list li {
  display: inline-block;
  list-style-type: none;
}

.container {
  max-width: 1170px;
  width: 1170px;
  margin: 0 auto;
}
body.hidden {
  position: relative;
  overflow: hidden;
}
/* body.hidden::after{
  content: '';
  position: absolute;
  background: rgba(00, 00, 00, 0.8);
  z-index: 2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
} */
.flex-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: wrap;
  flex-flow: wrap;
}
.main-heading {
  color: #ffffff;
  font-family: "noto_sansmedium";
  font-size: 60px;
  margin: 0;
}

.span-heading {
  font-family: "noto_sanslight";
  color: #9c9e9f;
  font-size: 22px;
  display: block;
}

/* primary btn and secondary btn css start*/
button.primary-btn,
button.secondary-btn {
  padding: 9px 20px;
  border-radius: 25px;
  border: none;
  font-size: 13px;
  background: #c39f42;
  color: #fff;
  cursor: pointer;
}
button.secondary-btn {
  background: transparent;
  border: 1px solid #c39f42;
  color: #c39f42;
  border-radius: 4px;
  transition: 0.3s ease;
}
button.secondary-btn:hover {
  background: rgba(216, 215, 215, 0.058823529411764705);
}
button.primary-btn:focus,
button.secondary-btn:focus {
  outline: none !important;
}

/* primary btn and secondary btn css end*/
.teritary-heading {
  font-size: 20px;
  color: #ffffff;
  font-family: "noto_sansbold";
  position: relative;
  text-align: center;
}
.teritary-heading::after {
  content: "";
  position: absolute;
  bottom: -10px;
  height: 1px;
  width: 55px;
  margin: auto;
  left: 0;
  right: 0;
  background: #c39f42;
}

.center {
  margin: 0 auto;
  display: block !important;
}

.a-link {
  color: #585858;
  font-size: 13px;
  text-decoration: underline;
  font-family: "noto_sansmedium";
}
.a-link:hover {
  color: #a3a3a3;
}
.or-space {
  position: relative;
  text-align: center;
}
.or-space span {
  background: #313131;
  color: #8e8e8e;
  border-radius: 100%;
  padding: 5px 6px;
  font-size: 11px;
  text-align: center;
  width: 30px;
  height: 30px;
}
.or-space::before {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  width: 45%;
  margin: auto;
  height: 1px;
  border: 1px dashed #505050;
}
.or-space::after {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  width: 45%;
  margin: auto;
  height: 1px;
  border: 1px dashed #505050;
}
.nopad {
  padding: 0 !important;
}

input:focus {
  outline: 1px solid #c39f42;
  border-radius: 3px;
}

.page-loader {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  display: inline-flex;
  align-items: center;
  text-align: center;
  z-index: 3;
}
.page-loader::after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(00, 00, 00, 0.8);
}
.page-loader .load-wrap img {
  width: 100%;
}
.page-loader .load-wrap {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 125px;
  border-radius: 50%;
  padding: 12px;
  z-index: 4;
}
.page-loader .load-wrap::after {
  content: "";
  position: absolute;
  top: -5px;
  bottom: 0;
  left: -5px;
  right: 0;
  margin: auto;
  background: transparent;
  width: 135px;
  height: 135px;
  border-top-left-radius: 100%;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  border-bottom-left-radius: 100%;
  border-top: 1px solid #c39f42;
  border-left: 1px solid transparent;
  border-right: 1px solid #c39f42;
  border-bottom: 1px solid #c39f42;
  animation: rotate 0.5s infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.bor-bot {
  border-bottom: 1px solid #383a3d;
}
.pointer {
  cursor: pointer;
}

.react-datepicker {
  background-color: #171717 !important;
  color: #000;
  border: 1px solid #27251d !important;
}

.custom-datepicker .react-datepicker__header {
  background-color: #171717 !important;
  box-shadow: 0px 3px 10px #00000080;
  border-bottom: 0;
}
.custom-datepicker .react-datepicker__day {
  color: #fff !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: #c39f42 !important;
}
.custom-datepicker .react-datepicker__header .react-datepicker__current-month,
.custom-datepicker .react-datepicker__day-name {
  color: #fff !important;
}
.custom-datepicker .react-datepicker__day,
.custom-datepicker .react-datepicker__day-name {
  padding: 5px 10px !important;
  width: 38px !important;
}

.Toastify__toast-body span.message {
  font-size: 13px !important;
  padding: 5px !important;
}

/* .otp-wrapper div:nth-of-type(1) input:focus{
  outline: 1px solid #C39F42;
} */

/* no-records found css begin */
.no-rec-lb {
  color: #7e7e7e;
  font-size: 14px;
  font-family: "noto_sansbold";
  margin: 0;
  font-weight: 500;
  width: 100%;
  margin-top: 10px;
}
.inner-state {
  text-align: center;
}
.inner-state span {
  display: block;
}
.no-rec-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
  min-height: 150px;
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.order-book .react-virtualized-container-fix {
  height: 603px !important;
}

.trade-wrapper-list .react-virtualized-container-fix{
  height: 500px !important;
}
.pro-trade .left-content-wrapper .left-content .trader-tab-content .trade-list .error-field{
  font-size: 10px !important;
}

.main-content .inner-content .main-search .adjustRow .error-field{
  top: 70px;
}
/* no-records found css ends */

span.primary-span{
  background-color: #191919;
  color: #C39F42;
  font-size: 13px;
  cursor: pointer;
  height: 35px;
  font-family: 'noto_sansmedium';
  padding: 5px 8px;
  border-radius: 50px;
  border: 1px solid #C39F42;
}
span.primary-span:hover{
  background: #C39F42;
  color: #ffffff;
}

.span-btn-wrap{
  text-align: center;
  display: inline-block;
}

.trader-tab-content .span-btn-wrap{
  width: 100%;
}
.email-error-field {
  font-size: 12px;
  color    : #BE2718;
}
.otp-wrapper div {
  display: inline-flex !important;
  justify-content: space-evenly;
}
.otp-wrapper .otp-error-field {
  border: 1px solid #be2718 !important;
}
.otp-wrapper .otp-input {
  width: 60% !important;
  display: inline-block;
  border: 0;
  height: 32px;
  border-radius: 3px;
  background: #313131;
  color: #A3A3A3;
  font-size: 14px;
}
.otp-wrapper .otp-input-focus {
  border: 1px solid #C39F42;
  border-radius: 3px;
}

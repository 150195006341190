.custom-toast {
  min-width: 25%;
  top: 4vh;
  width: auto;
  z-index: 99999999;
}
.custom-toast-success {
  background: #1ECFAA 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #1ECFAA;
  border-radius: 30px;
  color: #FFFFFF;
  font-size: 10px;
  min-height: 30px;
  padding: 0px 25px;
  min-width: 10vw;
}
.custom-toast-error {
  background: #BE2718 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #BE271826;
  border: 1px solid #BE2718;
  border-radius: 30px;
  color: #FFFFFF;
  font-size: 10px;
  min-height: 30px;
  padding: 0px 25px;
  min-width: 10vw;
}
.custom-toast-info {
  background: #333333 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #333333;
  border-radius: 30px;
  color: #FFFFFF;
  font-size: 10px;
  min-height: 30px;
  padding: 0px 25px;
  min-width: 10vw;
}
.message {
  margin-left: 15px;
  margin-right: 15px;
  width: 100%;
  text-align: center;
}
.toast-close-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0px, -50%);
}
.zoomOut {
  animation-name: zoomOut;
}
.zoomIn {
  animation-name: zoomIn;
}
@keyframes zoomOut {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.75;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.25;
  }
  100% {
    opacity: 0;
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

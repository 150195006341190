.uploadbox {
  height: 46px;
  border: 1px dashed #484848;
  border-radius: 3px;
  background: transparent;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.uploadbox .upload-label {
  font-size: 9px;
  color: #575757;
  padding-left: 10px;
}
.uploadbox .upload-link {
  font-size: 12px;
  color: #C39F42;
  text-decoration: underline;
  cursor: pointer;
}
.uploadbox .disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.uploadbox input[type="file"] {
  display: none;
}

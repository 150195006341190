.custom-radio-button input[type="radio"] {
  display: none;
}
.custom-radio-button input[type="radio"]:checked + .label-primary::before {
  border: 1px solid #ed8c2b;
}
.custom-radio-button input[type="radio"]:checked + .label-primary::after {
  display: inline-block;
}
.custom-radio-button input[type="radio"]:hover + .label-primary::before {
  border: 1px solid #ed8c2b;
}
.custom-radio-button input[type="radio"]:checked + .label-secondary::before {
  border: 1px solid #c39f42;
}
.custom-radio-button input[type="radio"]:checked + .label-secondary::after {
  display: inline-block;
}
.custom-radio-button input[type="radio"]:hover + .label-secondary::before {
  border: 1px solid #c39f42;
}
.custom-radio-button .custom-label {
  display: inline-block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 12px;
  color: #a7a7a7;
}
.custom-radio-button .label-primary {
  line-height: 12px;
}
.custom-radio-button .label-secondary {
  line-height: 14px;
}
.custom-radio-button .label-primary::before {
  content: "";
  width: 12px;
  height: 12px;
  border: 1px solid #6c7a88;
  border-radius: 50px;
  position: absolute;
  top: 0px;
  left: 0px;
}
.custom-radio-button .label-primary::after {
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 50px;
  background: #ed8c2b 0% 0% no-repeat padding-box;
  position: absolute;
  top: 4px;
  left: 4px;
  display: none;
}
.custom-radio-button .label-secondary::before {
  content: "";
  width: 14px;
  height: 14px;
  border-radius: 50px;
  border: 1px solid #7e7e7e;
  position: absolute;
  top: 0px;
  left: 0px;
}
.custom-radio-button .label-secondary::after {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50px;
  background: #c39f42 0% 0% no-repeat padding-box;
  position: absolute;
  top: 4px;
  left: 4px;
  display: none;
}

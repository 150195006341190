.checkbox-container {
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  padding-left: 20px;
  font-size: 13px;
  font-family: "Roboto-Regular";
  margin-bottom: 0px;
}
.checkbox-container .disabled-checkbox {
  cursor: not-allowed;
}
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox-container .opacity {
  opacity: 0.2 !important;
  cursor: not-allowed !important;
}
.checkbox-container .custom-checkbox {
  position: absolute;
  top: 3px;
  left: 0;
  width: 14px;
  height: 14px;
  background-color: transparent;
  border: 1px solid #6C7A88;
  border-radius: 3px;
  box-shadow: 0px 4px 10px #E7CB6033;
}
.checkbox-container .custom-checkbox::after {
  content: '';
  position: absolute;
  display: none;
  width: 10px;
  height: 6px;
  border-left: 2px solid #C39F42;
  border-bottom: 2px solid #C39F42;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  top: 2px;
  left: 1px;
}
.checkbox-container .custom-checkbox:focus {
  border: 1px solid #6C7A88;
}
.checkbox-container:hover input ~ .custom-checkbox {
  border: 1px solid #6C7A88;
}
.checkbox-container input:checked ~ .custom-checkbox {
  background-color: transparent;
  border: 1px solid #C39F42;
}
.checkbox-container input:disabled ~ .custom-checkbox {
  opacity: 0.5;
  border: 1px solid #6C7A88;
  cursor: not-allowed;
}
.checkbox-container input:checked ~ .custom-checkbox::after {
  display: block;
}

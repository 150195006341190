.custom-dropdown {
  width: 100%;
  height: auto;
  background: #333333 0% 0% no-repeat padding-box;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}
.custom-dropdown .custom-dropdown-toggle {
  padding: 0px 10px;
  background: #333333 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  border-radius: 5px;
  border: none;
  color: #ffffff;
  font-size: 13px;
}
.custom-dropdown .custom-dropdown-toggle .dropdown-selected {
  width: 100%;
}
.custom-dropdown .custom-dropdown-toggle .dropdown-selected input {
  height: auto;
  cursor: pointer;
  padding: 10px 0px !important;
  border: 1px solid #333333;
  color: #ffffff;
  background-color: #333333;
  font-size: 13px;
  font-weight: bold;
}
.custom-dropdown .custom-dropdown-toggle input:disabled {
  cursor: not-allowed;
  width: 100%;
}
.custom-dropdown .custom-dropdown-toggle input:focus {
  outline: none;
  border: 1px solid #333333;
}
.custom-dropdown .custom-dropdown-toggle .dropdown-toggle-img img {
  margin-bottom: 3px;
}
.custom-dropdown .custom-dropdown-toggle::after {
  display: none !important;
}
.custom-dropdown .custom-dropdown-menu {
  width: 100%;
  min-width: auto;
  background: #333333 0% 0% no-repeat padding-box;
  border-radius: 0px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-top: -2px !important;
  padding: 0px !important;
  border: none;
  max-height: 155px;
  overflow: hidden;
  overflow-y: auto;
}
.custom-dropdown .custom-dropdown-item {
  color: #ffffff;
  font-size: 14px;
  padding: 5px 10px;
}
.custom-dropdown .custom-dropdown-item:hover {
  background: #c39f42;
}
.adjustRow {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

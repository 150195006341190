.footer-section {
  border-top: 1px solid rgba(156, 158, 159, 0.12);
  padding: 50px 0;
}
.foot-txt label {
  font-size: 13px;
  color: #9C9E9F;
  margin-bottom: 10px;
  display: block;
}
.ft-copyright {
  font-size: 11px;
  color: #757575;
  margin-top: 25px;
}
.foot-ip input:focus {
  outline: none;
}
span.sp-social {
  height: 35px;
  width: 35px;
  background: #333333;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  transition: 0.3s ease;
}
.social-icons {
  margin-left: auto;
  display: flex;
}
.sp-social {
  cursor: pointer;
}
.sp-social svg {
  height: 15px;
  width: 15px;
}
.foot-bg {
  display: flex;
  align-items: center;
}
.sp-social svg path {
  fill: #e6e6e6;
}
.foot-ip input {
  border: 1px solid transparent;
  padding: 9px 10px;
  border-radius: 3px;
  background: #333333;
  font-size: 13px;
  color: #fff;
  width: 50%;
  margin-right: 10px;
}
span.sp-social:hover svg path {
  fill: #c39f42;
}
span.sp-social:hover {
  transform: scale(1.1);
}
label.copy-txt,
.ft-txt-p {
  font-size: 14px;
  color: #9C9E9F;
  text-align: center;
  font-family: 'noto_sansregular';
  margin-bottom: 0;
}
.ft-txt-p {
  font-size: 11px;
  width: 100%;
  margin: 20px 0px;
  text-align: center;
}
p.ft-txt-p.mr-btm {
  margin-bottom: 0;
  text-align: center;
  width: 85%;
  margin: auto;
}
.foot-txt {
  margin: 0 auto;
}

.Tabs .tabs-border {
  width: 250px;
  background: #191919;
  border-radius: 60px;
  opacity: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 3px !important;
}
.Tabs .primary-border {
  border: 1px solid #c39f42;
}
.Tabs .secondary-border {
  border: 1px solid #333333;
}
.Tabs .buy-border {
  border: 1px solid #1ECFAA80;
}
.Tabs .sell-border {
  border: 1px solid #FF2E7A80;
}
.Tabs .tabs-active {
  width: 50%;
  cursor: pointer;
  color: #ffffff !important;
  font-size: 14px;
  background: #c39f42;
  border-radius: 60px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  font-family: "noto_sansmedium";
}
.Tabs .primary-active {
  background-color: #c39f42;
}
.Tabs .secondary-active {
  background: #333333;
  color: #fff;
  font-family: "noto_sansmedium";
  font-size: 11px;
}
.Tabs .sell-active {
  background: #FF2E7A 0% 0% no-repeat padding-box;
  color: #ffffff;
}
.Tabs .buy-active {
  background: #1ECFAA 0% 0% no-repeat padding-box;
  color: #ffffff;
}
.Tabs .tabs-normal {
  width: 50%;
  cursor: pointer;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  z-index: 1;
}
.Tabs .primary-normal {
  color: #c39f42;
}
.Tabs .secondary-normal {
  font-size: 11px;
  color: #a3a3a3;
}
.Tabs .buy-normal {
  color: #767676;
}
.Tabs .sell-normal {
  color: #767676;
}

.inputdropdown {
  background: #333333 0% 0% no-repeat padding-box;
  display: flex;
  height: 42px;
  border-radius: 5px;
}
.inputdropdown-inputbox {
  width: 65%;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  height: 40px;
}
.inputdropdown-inputbox input {
  height: 38px !important;
}
.inputdropdown-ddbox {
  width: 35%;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  height: 40px;
}
.ddbox-toggle {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  height: 40px !important;
}
.ddbox-toggle input {
  height: 38px !important;
}
.divider {
  height: 20px;
  width: 1px;
  background-color: #5C5C5C;
  align-self: center;
}
.error-inputdropdown {
  border: 1px solid #BE2718;
}

.sweet-loading {
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  margin: auto;
  width: 35px;
  height: 35px;
}
.sweet-loading .loading {
  width: 35px;
  height: 35px;
}
